import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Form,
  Modal,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useDebounceSearch from '../../hooks/useDebounceSearch';
import {
  getEnterpriseVoucher,
  getVoucher,
  setEnterpriseVoucher,
  deleteEnterpriseVoucher,
} from '../../services/stripe.service';
import { NotFound } from '../../helpers/catalog.errors';
import ErrorFormField from '../ErrorFormField/ErrorFormField';
import { AlertContext } from '../../contexts/alert.context';

const VoucherModal = ({ show, onClose, enterpriseId }) => {
  const { t } = useTranslation();
  const [voucherId, setVoucherId] = useState('');
  const [voucher, setVoucher] = useState();
  const [exists, setExists] = useState(false);
  const [errors, setErrors] = useState([]);
  const { setNotif } = useContext(AlertContext);

  const checkVoucherId = useDebounceSearch(async (value) => {
    const res = await getVoucher(value);
    if (res.hasError(NotFound.httpCode)) {
      setErrors(['voucherId']);
    } else if (res.success) {
      setErrors([]);
      setVoucher(res.data);
    }
  });

  const handleVoucherChange = (e) => {
    setVoucherId(e.target.value);
    checkVoucherId(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await setEnterpriseVoucher(enterpriseId, voucherId);
    if (res.success) {
      setVoucherId();
      onClose();
      setNotif({
        message: t('Voucher.associationSuccess'),
        variant: 'success',
      });
      setExists(true);
    } else {
      setNotif({
        message: t('Voucher.associationFailed', { code: res.data.customCode }),
        variant: 'danger',
      });
    }
  };

  const deleteVoucher = async () => {
    const res = await deleteEnterpriseVoucher(enterpriseId, voucher.id);
    if (res.success) {
      setVoucher(null);
      setExists(false);
    }
  };

  const fetchVoucher = async () => {
    const res = await getEnterpriseVoucher(enterpriseId);
    if (res.success) {
      setVoucher(res.data.stripe);
      setExists(true);
    } else {
      setVoucher(null);
    }
  };

  useEffect(() => {
    if (enterpriseId) {
      fetchVoucher();
      setExists(false);
    }
  }, [enterpriseId]);

  return (
    <Modal centered show={show} onHide={onClose}>
      <Modal.Header closeButton>
        {t('Voucher.titleModal')}
      </Modal.Header>
      <Modal.Body>
        {!exists ? (
          <Form.Group>
            <Form.Label className="label">{t('Voucher.id')}</Form.Label>
            <Form.Control placeholder={t('Voucher.enterVoucherId')} onChange={handleVoucherChange} />
            <ErrorFormField field="voucherId" errors={errors}>
              {t('Voucher.notFound')}
            </ErrorFormField>
          </Form.Group>
        ) : (
          <div>
            {t('Voucher.alreadyExists')}
          </div>
        )}
        {voucher ? (
          <Card className="mt-4 p-3">
            <div className="d-flex justify-content-center">
              <div className="bold">
                {voucher.name}
              </div>
            </div>
            {
              exists ? (
                <Button variant="danger" size="sm" onClick={deleteVoucher} className="mt-2">
                  {t('Voucher.delete')}
                </Button>
              ) : null
            }
          </Card>
        ) : null}
        <div className="mt-3">
          <div>{t('Voucher.notabene')}</div>
          <ul className="italic">
            <li>{t('Voucher.createInStripe')}</li>
            <li>{t('Voucher.willBeDispatch')}</li>
            <li>{t('Voucher.bestWillBeUsed')}</li>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>{t('Common.cancel')}</Button>
        <Button
          variant="primary"
          disabled={!voucher || exists}
          onClick={handleSubmit}
        >
          {t('Common.validate')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

VoucherModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  enterpriseId: PropTypes.string.isRequired,
};

export default VoucherModal;
