import { useCallback, useMemo } from 'react';
import { debounce } from 'lodash';

const useDebounceSearch = (callback, ms = 300, deps = []) => {
  const listenChange = useCallback(
    debounce((e) => {
      callback(e);
    }, ms),
    deps,
  );

  return useMemo(() => listenChange, deps);
};

export default useDebounceSearch;
