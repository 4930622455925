import { safeFetch } from '../helpers/response-handler';

const base = `${process.env.REACT_APP_URL_ENTERPRISE_API}/v1/enterprise`;

export const getVoucher = (id) => (
  safeFetch(`${base}/stripe/vouchers/${id}`)
);

export const getEnterpriseVoucher = (id) => (
  safeFetch(`${base}/stripe/enterprises/${id}/vouchers`)
);

export const setEnterpriseVoucher = (enterpriseId, voucherId) => (
  safeFetch(
    `${base}/stripe/vouchers/${voucherId}/enterprises/${enterpriseId}`,
    { method: 'POST' },
  )
);

export const deleteEnterpriseVoucher = (enterpriseId, voucherId) => (
  safeFetch(
    `${base}/stripe/enterprises/${enterpriseId}/vouchers/${voucherId}`,
    { method: 'DELETE' },
  )
);
